import React, { useState } from 'react';
import {
  DropDown,
  StyledButton,
  withTheme,
} from '@canopysecurity/component-library';
import { useNavigate } from 'react-router-dom';
import PageTitleAndSub from '../Components/PageTitleAndSub';
import styled from 'styled-components';
import { HeadingText, SectionTitleText } from '../StyledComponents/Typography';
import cutecondor from '../assets/cute-condor.png';
import CondorSpinner from '../Components/CondorSpinner';
import pixelRatio from '@canopysecurity/component-library/utils/pixelRatio';
import {
  DeployBaseTableRow,
  HomeBaseTableRow,
} from '../StyledComponents/Table';
import DeployPackageTableRow from '../Components/DeployedPackagesTable';
import { Semver } from '../Components/Semver';
import { ArtifactTable } from './Home/ArtifactTable';
import {
  Deployment,
  Release,
  useGetActiveDeploymentsQuery,
  useGetDeviceTypesQuery,
  useGetInactiveDeploymentsQuery,
  useGetReleasesQuery,
} from '../gql/generated';
import { useOktaAuth } from '@okta/okta-react';
import { PromotionModalProvider } from '../Context/PromotionModal/PromotionModalProvider';

const Borderline = styled.div`
  margin-top: 4%;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
`;

const TableContainer = styled.div`
  margin-top: 3%;
  padding: 0 5% 0 5%;
  height: 40vh;
  margin-bottom: 4em;
`;

const ReleasePackageTable = styled.div`
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 1% 2% 1% 2%;
  height: 80%;
  overflow-y: hidden;
`;

const ThemedReleasePackageTable = withTheme(
  styled(ReleasePackageTable)`
    background-color: ${({ theme }) => theme.background.main};
  `,
);

const TableRows = styled.div`
  grid-template-columns: 25fr 25fr 50fr;
  display: grid;
  width: 100%;
  height: 80%;
  overflow-y: auto;
`;

const ReleasePackageTableHeaderRow = withTheme(styled(HomeBaseTableRow)`
  display: grid;
  grid-template-columns: 20fr 35fr 15fr 15fr;
  align-items: center;
  background-color: ${({ theme }) => theme.background.main};
`);

const DeployPackageTable = styled(ReleasePackageTable)`
  grid-template-columns: 20fr 20fr 20fr 20fr 20fr;
`;

const DropdownContainer = styled.div``;
const DeployPackageTableHeaderRow = withTheme(styled(DeployBaseTableRow)`
  display: grid;
  grid-template-columns: 20fr 20fr 20fr 20fr 20fr 20fr;
  background-color: ${({ theme }) => theme.background.main};
`);

const ButtonWrapper = styled.div`
  width: 30%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ReleasePackageTableHeader = ['RELEASE TITLE', 'DEVICE TYPE', ''];
const DeployPackageTableHeader = [
  'RELEASE VERSION',
  'RELEASE DATE',
  'ENVIRONMENT',
  'DEVICE TYPE',
  'LAST DEPLOYED BY',
];

const selectedReleaseFilters: Record<any, string[]> = {
  selectedDeviceType: [],
};

const selectedDeployFilters: Record<any, string[]> = {
  selectedDeviceType: [],
  selectedEnvironment: [],
};

const isFilterSelected = (filterList: Record<string, string[]>) =>
  Object.values(filterList).flat().length !== 0;

const filterReleasePackages = (
  filterGroup: Record<any, string[]>,
  releasePackages: Release[],
) => {
  return releasePackages.filter((releasePackage) =>
    filterGroup.selectedDeviceType.length !== 0
      ? !!releasePackage.deviceType &&
        filterGroup.selectedDeviceType.includes(releasePackage.deviceType)
      : true,
  );
};

const filterDeployedPackages = (
  filterGroup: Record<any, string[]>,
  deployedPackages: Deployment[],
) => {
  return deployedPackages.filter(
    (deployedPackage) =>
      (filterGroup.selectedDeviceType.length !== 0
        ? !!deployedPackage.release?.deviceType &&
          filterGroup.selectedDeviceType.includes(
            deployedPackage.release?.deviceType,
          )
        : true) &&
      (filterGroup.selectedEnvironment.length !== 0
        ? filterGroup.selectedEnvironment
        : true),
  );
};

export const Home = () => {
  const navigate = useNavigate();
  const okta = useOktaAuth();
  const name = okta.authState?.idToken?.claims?.name ?? 'Bird Enthusiast';
  const { data: releasesData, loading } = useGetReleasesQuery({
    fetchPolicy: 'no-cache',
  });
  const releases = (releasesData?.getReleases?.items ?? []).filter(
    Boolean,
  ) as Release[];
  const { data: devices } = useGetDeviceTypesQuery();
  const environmentList = ['BRANCH', 'DEV', 'STAGING', 'QE', 'PROD'];
  const { data: activeDeploymentsData, loading: activeDeploymentsLoading } =
    useGetActiveDeploymentsQuery({
      fetchPolicy: 'no-cache',
    });
  const activeDeployments = (
    activeDeploymentsData?.getDeployments?.items ?? []
  ).filter(Boolean) as Deployment[];
  const { data: inactiveDeploymentsData, loading: inactiveDeploymentsLoading } =
    useGetInactiveDeploymentsQuery({
      fetchPolicy: 'no-cache',
    });
  const inactiveDeployments = (
    inactiveDeploymentsData?.getDeployments?.items ?? []
  ).filter(Boolean) as Deployment[];

  const deviceList = (devices?.__type?.enumValues ?? []).map(
    (device: { name: string }) => device.name,
  );
  const [selectedReleaseFilter, setSelectedReleaseFilter] = useState(
    selectedReleaseFilters,
  );

  const [selectedDeployFilter, setSelectedDeployFilter] = useState(
    selectedDeployFilters,
  );
  return (
    <div style={{ paddingBottom: '5%' }}>
      <PageTitleAndSub
        title={'Welcome, ' + name}
        subtitle="Get started by packaging a release."
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ButtonWrapper>
          <StyledButton
            onPress={() => navigate('/create-release-package')}
            style={{ padding: '3%' }}
          >
            Create a new Release Package
          </StyledButton>
        </ButtonWrapper>
        <ButtonWrapper>
          <StyledButton
            onPress={() => navigate('/manage-tags')}
            style={{ padding: '3%' }}
          >
            Manage Tags
          </StyledButton>
        </ButtonWrapper>
      </div>
      <Borderline />
      {loading ? (
        <CondorSpinner />
      ) : (
        <TableContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '16px',
            }}
            data-testid="ota-packages-table"
          >
            <SectionTitleText>OTA Packages</SectionTitleText>
            <div
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                paddingRight: '8px',
              }}
              onClick={() => {
                setSelectedReleaseFilter(selectedReleaseFilters);
              }}
            >
              <HeadingText>Clear All</HeadingText>
            </div>
            <div style={{ marginLeft: '8px' }}>
              <DropdownContainer>
                <DropDown
                  items={deviceList}
                  onValueChange={(value: string) =>
                    setSelectedReleaseFilter({ selectedDeviceType: [value] })
                  }
                  placeholderText={'Filter by Device'}
                  dropdownListContainerStyle={{
                    width: pixelRatio.widthPercentageToDP('10'),
                    zIndex: 99,
                  }}
                  selectedValue={selectedReleaseFilter.selectedDeviceType[0]}
                />
              </DropdownContainer>
            </div>
          </div>

          <ThemedReleasePackageTable>
            <ReleasePackageTableHeaderRow>
              {ReleasePackageTableHeader.map((header) => (
                <SectionTitleText key={header}>{header}</SectionTitleText>
              ))}
            </ReleasePackageTableHeaderRow>
            {!releases || releases?.length === 0 ? (
              <img
                src={cutecondor}
                alt="No artifacts to display"
                style={{ maxHeight: '70%' }}
              />
            ) : (
              <TableRows>
                <PromotionModalProvider>
                  <DeployPackageTableRow
                    tableType="release"
                    deployedPackages={
                      isFilterSelected(selectedReleaseFilter)
                        ? filterReleasePackages(selectedReleaseFilter, releases)
                        : releases
                    }
                  />
                </PromotionModalProvider>
              </TableRows>
            )}
          </ThemedReleasePackageTable>
        </TableContainer>
      )}

      {activeDeploymentsLoading ? (
        <CondorSpinner />
      ) : (
        <TableContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '16px',
            }}
          >
            <SectionTitleText>
              Deployed OTA Updates - Active Deployments
            </SectionTitleText>
            <div
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                paddingRight: '8px',
              }}
              onClick={() => setSelectedDeployFilter(selectedDeployFilters)}
            >
              <HeadingText>Clear All</HeadingText>
            </div>
            <div>
              <DropdownContainer>
                <DropDown
                  items={environmentList}
                  onValueChange={(value: string) =>
                    setSelectedDeployFilter((prevValue) => ({
                      ...prevValue,
                      selectedEnvironment: [value],
                    }))
                  }
                  placeholderText={'Filter by Environment'}
                  dropdownListContainerStyle={{
                    width: pixelRatio.widthPercentageToDP('10'),
                    zIndex: 99,
                  }}
                  selectedValue={selectedDeployFilter.selectedEnvironment[0]}
                />
              </DropdownContainer>
            </div>
            <div style={{ marginLeft: '8px' }}>
              <DropdownContainer>
                <DropDown
                  items={deviceList}
                  onValueChange={(value: string) =>
                    setSelectedDeployFilter((prevValue) => ({
                      ...prevValue,
                      selectedDeviceType: [value],
                    }))
                  }
                  placeholderText={'Filter by Device'}
                  dropdownListContainerStyle={{
                    width: pixelRatio.widthPercentageToDP('10'),
                    zIndex: 99,
                  }}
                  selectedValue={selectedDeployFilter.selectedDeviceType[0]}
                />
              </DropdownContainer>
            </div>
          </div>
          <DeployPackageTable>
            <DeployPackageTableHeaderRow>
              {DeployPackageTableHeader.map((header) => (
                <SectionTitleText key={header}>{header}</SectionTitleText>
              ))}
            </DeployPackageTableHeaderRow>
            {!activeDeployments || activeDeployments.length === 0 ? (
              <img
                src={cutecondor}
                alt="No deployed releases to display"
                style={{ maxHeight: '70%' }}
              />
            ) : (
              <TableRows>
                <DeployPackageTableRow
                  deployedPackages={
                    isFilterSelected(selectedDeployFilter)
                      ? filterDeployedPackages(
                          selectedDeployFilter,
                          activeDeployments,
                        )
                      : activeDeployments
                  }
                />
              </TableRows>
            )}
          </DeployPackageTable>
        </TableContainer>
      )}

      {inactiveDeploymentsLoading ? (
        <CondorSpinner />
      ) : (
        <TableContainer>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingBottom: '16px',
            }}
          >
            <SectionTitleText>
              Deployed OTA Updates - Inactive Deployments
            </SectionTitleText>
            <div
              style={{
                marginLeft: 'auto',
                cursor: 'pointer',
                paddingRight: '8px',
              }}
              onClick={() => setSelectedDeployFilter(selectedDeployFilters)}
            >
              <HeadingText>Clear All</HeadingText>
            </div>
            <div>
              <DropdownContainer>
                <DropDown
                  items={environmentList}
                  onValueChange={(value: string) =>
                    setSelectedDeployFilter((prevValue) => ({
                      ...prevValue,
                      selectedEnvironment: [value],
                    }))
                  }
                  placeholderText={'Filter by Environment'}
                  dropdownListContainerStyle={{
                    width: pixelRatio.widthPercentageToDP('10'),
                    zIndex: 99,
                  }}
                  selectedValue={selectedDeployFilter.selectedEnvironment[0]}
                />
              </DropdownContainer>
            </div>
            <div style={{ marginLeft: '8px' }}>
              <DropdownContainer>
                <DropDown
                  items={deviceList}
                  onValueChange={(value: string) =>
                    setSelectedDeployFilter((prevValue) => ({
                      ...prevValue,
                      selectedDeviceType: [value],
                    }))
                  }
                  placeholderText={'Filter by Device'}
                  dropdownListContainerStyle={{
                    width: pixelRatio.widthPercentageToDP('10'),
                    zIndex: 99,
                  }}
                  selectedValue={selectedDeployFilter.selectedDeviceType[0]}
                />
              </DropdownContainer>
            </div>
          </div>
          <DeployPackageTable>
            <DeployPackageTableHeaderRow>
              {DeployPackageTableHeader.map((header) => (
                <SectionTitleText key={header}>{header}</SectionTitleText>
              ))}
            </DeployPackageTableHeaderRow>
            {!inactiveDeployments || inactiveDeployments?.length === 0 ? (
              <img
                src={cutecondor}
                alt="No deployed releases to display"
                style={{ maxHeight: '70%' }}
              />
            ) : (
              <TableRows>
                <DeployPackageTableRow
                  deployedPackages={
                    isFilterSelected(selectedDeployFilter)
                      ? filterDeployedPackages(
                          selectedDeployFilter,
                          inactiveDeployments,
                        )
                      : inactiveDeployments
                  }
                />
              </TableRows>
            )}
          </DeployPackageTable>
        </TableContainer>
      )}
      <ArtifactTable
        deviceList={deviceList}
        activeDeployments={activeDeployments}
      />
      <Semver />
    </div>
  );
};
